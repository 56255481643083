
            /* Tercihe göre değiştirilmiş Cookiespool Banner ve Popup CSS değerleri */

            /* Banner css değişiklikleri */
            #cookiespool-banner {
                background: #edeced !important;   /* Banner background renk ayarı için. Default: #edeced */
                color: #000000 !important;        /* Banner font renk ayarı için. Default: #000000 */
                font-size: 0.8em !important;        /* Banner font büyüklüğü, default: 1em*/
                opacity: 0.8 !important;          /* Banner Opacity, Default 1 */
                width: 30% !important;;           /* Banner genişliğini ayarlamak için. Default: 90% */
            }

            #cookiespool-banner #bannerPolicy {
                color: #0000ff !important;}        /* Banner'daki Çerez Politikası linkinin font renk ayarı için. Default: #0000ff */ 

            #cookiespool-banner .cookiespool-cookies {
                display: flex !important; }	  /* Banner’daki Buton’ların yerleşimi. Yazının altında gözükmesi için block yapılır, Default: flex */

            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
                flex-direction: column !important;	/* Banner’daki Buton’ların pozisyonu. Tek satırda gözükmesi için row yapılır, Default: column */
                margin-left: 10px !important;}		/* Banner’daki Buton grubunun Sol Marjini, row için 0 px, Column için Default: 10px */ 

            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {    
                color: #ffffff !important;              /* Banner’daki Buton’ların font renk ayarı için, Default: #ffffff */
                margin: 5px 10px !important;            /* Buton Margin ayarı, Default: 5px, 35px */
                background: #1c87f6 !important;         /* Banner’daki Buton’ların background renk ayarı için, Default: #1c87f6 */
            }  

            /* Popup css değişiklikleri */    
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window {           
                background: #edeced !important; }           /* Popup window background renk ayarı için. Default: #edeced */
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-header {   
                background: #1c87f6 !important;             /* Popup header background renk ayarı için. Default: #1c87f6 */
                color: #ffffff !important;}                 /* Popup header font renk ayarı için. Default: #ffffff */
            #cookiespool-popup .cookiespool-window .cookiespool-title {
                color: #ffffff !important;      /* Popup Başlık font renk ayarı için. Default: #ffffff */
            }
            #cookiespool-popup .cookiespool-window .cookiespool-subtitle {
                color: #ffffff !important;      /* Popup Alt Başlık font renk ayarı için. Default: #ffffff */
            }
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-header #policy {
                color: #ffffff !important;}     /* Popup'taki Çerez Politikası linkinin font renk ayarı için. Default: #ffffff */
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions button {
                background: #1c87f6 !important;             /* Popup buton background renk ayarı için. Default: #1c87f6 */
                color: #ffffff !important;}      /* Popup buton font renk ayarı için. Default: #ffffff */
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions #cancel {
                background: #1c87f6 !important;             /* Popup Cancel buton background renk ayarı için. Default: #1c87f6 */
                color: #ffffff !important;}      /* Popup Cancel buton font renk ayarı için. Default: #ffffff */

            /* Responsive ekran için @media ayarları, aşağıdaki bloğu lütfen değiştirmeyin */
            @media (max-width: 1024px) {
            #cookiespool-banner {
                font-size: 0.9em !important;
                width: 90% !important; }
            #cookiespool-banner .cookiespool-cookies {
                display: flex !important;}
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
                display: flex !important;
                flex-direction: column !important; }
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
                margin: 3px 25px !important;
                font-size: 0.8em !important; } }

            @media (max-width: 500px) {
            #cookiespool-banner {
                font-size: 0.8em !important;
                width: 90% !important; }
            #cookiespool-banner .cookiespool-cookies {
                display: block !important;
                padding-bottom: 0 !important;}
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
                flex-direction: row !important;
                margin: 10px !important; }
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
                margin: 3px 3px !important;
                font-size: 0.8em !important; } }
        