@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500;700&family=Lato:wght@400;900&display=swap");
#cookiespool-banner {
  z-index: 10000000;
  position: fixed;
  transform: translateX(5.5%);
  bottom: 0;
  background: #EDECED !important;
  color: black !important;
  width: 90%;
  display: none;
  justify-content: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  animation: slide-in 1s ease-in; }

@keyframes slide-in {
  from {
    bottom: -300px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }
  #cookiespool-banner #bannerPolicy {
    color: blue !important;
    text-decoration: underline; }
  #cookiespool-banner .cookiespool-cookies {
    padding: 15px 0;
    max-width: 90%;
    display: flex;
    align-items: center;
    font-family: 'Hind Siliguri', sans-serif; }
    #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
      display: flex;
      flex-direction: column;
      margin-left: 10px; }
      #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
        color: white !important;
        background: #1C87F6 !important;
        border: none;
        padding: 7px;
        margin: 5px 35px;
        width: 100%;
        border-radius: 5px;
        font-size: 1em !important;
        line-height: normal !important;
        text-transform: none !important;
        font-family: 'Hind Siliguri', sans-serif !important;
        letter-spacing: normal !important;
        text-align: center !important; }
        #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button:hover {
          background: #CECDCE !important;
          color: #A5A3A5 !important;
          cursor: pointer; }

#cookiespool-modal-container {
  z-index: 10000000;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5) !important; }
  #cookiespool-modal-container #cookiespool-popup * {
    padding: 0;
    margin: 0;
    font-family: 'Hind Siliguri', sans-serif; }
  #cookiespool-modal-container #cookiespool-popup .cookiespool-window {
    background: #EDECED !important; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-header {
      background: #1C87F6 !important;
      color: white !important;
      padding: 15px 35px; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-header #policy {
        float: right;
        color: white !important;
        text-decoration: underline; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions {
      padding: 15px;
      text-align: right; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions button {
        color: white !important;
        background: #1C87F6 !important;
        border: none;
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        font-size: 1em !important;
        line-height: normal !important;
        text-transform: none !important;
        font-family: 'Hind Siliguri', sans-serif !important;
        letter-spacing: normal !important;
        text-align: center !important; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions #cancel {
        cursor: pointer; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions #accept {
        cursor: pointer; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions #cookiespoollink {
        float: left;
        color: blue !important;
        font-size: 13px;
        padding: 5px; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions #consentid {
        float: left;
        color: #616061 !important;
        font-size: 13px;
        padding: 2px; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window ul {
      list-style-type: none; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-title {
      font-size: 18px;
      color: white !important; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-subtitle {
      color: white !important; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window input[type="checkbox"] {
      width: 40px !important;
      height: 20px !important;
      -webkit-appearance: none !important;
      background: #EBE9ED !important;
      border-radius: 20px !important;
      outline: none !important;
      position: inherit !important; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window input:checked[type='checkbox'] {
      background: #37C94C !important; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window input[type="checkbox"]:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      transform: scale(0.8);
      border-radius: 20px;
      background: white !important;
      transition: 0.4s; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window input:checked[type="checkbox"]:before {
      transform: translateX(20px); }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-pad {
      padding-bottom: 10px; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion li .cookiespool-panel button {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      color: black !important;
      font-size: 15px;
      cursor: pointer;
      font-size: 1em !important;
      line-height: normal !important;
      text-transform: none !important;
      font-family: 'Hind Siliguri', sans-serif !important;
      letter-spacing: normal !important;
      text-align: center !important; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window ul {
      list-style-type: none; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion .cookiespool-content {
      font-size: 14px;
      background: #EDECED !important;
      color: black !important;
      padding: 0 30px;
      margin: 10px 0;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion li .cookiespool-panel {
      padding: 15px 35px;
      background: #FFFFFF !important;
      margin: 2px 20px 20px;
      border-radius: 5px; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion li .cookiespool-panel p, #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion li .cookiespool-panel .cookiespool-option {
        text-align: right; }
    #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion li .cookiespool-ss .cookiespool-option {
      float: right;
      margin-right: 50px;
      margin-top: 16px; }
      #cookiespool-modal-container #cookiespool-popup .cookiespool-window #accordion li .cookiespool-ss .cookiespool-option .cookiespool-warning {
        color: #A9A8A9 !important; }

#cookiespool-footer {
  z-index: 10000000;
  position: fixed;
  padding: 5px 5px 10px 5px;
  bottom: 0;
  animation: slide-right 1s ease-in; }

@keyframes slide-right {
  from {
    left: -300px;
    opacity: 0; }
  to {
    left: 0;
    opacity: 1; } }

.cookiespool-footerbutton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 1em !important;
  line-height: normal !important;
  text-transform: none !important;
  font-family: 'Hind Siliguri', sans-serif !important;
  letter-spacing: normal !important;
  text-align: center !important; }
  .cookiespool-footerbutton .cookiespool-footer-img {
    max-height: 50px;
    max-width: 50px; }

.cookiespool-footertooltip {
  border-bottom: 1px dotted black; }
  .cookiespool-footertooltip .cookiespool-footertooltiptext {
    visibility: hidden;
    width: 140px;
    font-size: 13px;
    background-color: black !important;
    color: #fff !important;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute !important;
    cursor: pointer;
    left: 100%;
    top: 0; }
  .cookiespool-footertooltip:hover .cookiespool-footertooltiptext {
    visibility: visible !important; }

@media (max-width: 1024px) {
  #cookiespool-banner {
    font-size: 0.9em !important;
    width: 90%  !important; }
  #cookiespool-banner .cookiespool-cookies {
    display: flex !important; }
  #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
    display: flex  !important;
    flex-direction: column  !important; }
  #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
    margin: 3px 25px  !important;
    font-size: 0.8em !important; } }

@media (max-width: 500px) {
  #cookiespool-popup {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #cookiespool-banner {
    font-size: 0.8em !important;
    width: 90%  !important; }
  #cookiespool-banner .cookiespool-cookies {
    display: block !important;
    padding-bottom: 0  !important; }
  #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
    flex-direction: row  !important;
    margin: 10px  !important; }
  #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
    margin: 3px 3px  !important;
    font-size: 0.8em !important; } }

@media (min-width: 500px) {
  #cookiespool-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px; } }


            /* Tercihe göre değiştirilmiş Cookiespool Banner ve Popup CSS değerleri */

            /* Banner css değişiklikleri */
            #cookiespool-banner {
                background: #edeced !important;   /* Banner background renk ayarı için. Default: #edeced */
                color: #000000 !important;        /* Banner font renk ayarı için. Default: #000000 */
                font-size: 0.8em !important;        /* Banner font büyüklüğü, default: 1em*/
                opacity: 0.8 !important;          /* Banner Opacity, Default 1 */
                width: 30% !important;;           /* Banner genişliğini ayarlamak için. Default: 90% */
            }

            #cookiespool-banner #bannerPolicy {
                color: #0000ff !important;}        /* Banner'daki Çerez Politikası linkinin font renk ayarı için. Default: #0000ff */ 

            #cookiespool-banner .cookiespool-cookies {
                display: flex !important; }	  /* Banner’daki Buton’ların yerleşimi. Yazının altında gözükmesi için block yapılır, Default: flex */

            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
                flex-direction: column !important;	/* Banner’daki Buton’ların pozisyonu. Tek satırda gözükmesi için row yapılır, Default: column */
                margin-left: 10px !important;}		/* Banner’daki Buton grubunun Sol Marjini, row için 0 px, Column için Default: 10px */ 

            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {    
                color: #ffffff !important;              /* Banner’daki Buton’ların font renk ayarı için, Default: #ffffff */
                margin: 5px 10px !important;            /* Buton Margin ayarı, Default: 5px, 35px */
                background: #1c87f6 !important;         /* Banner’daki Buton’ların background renk ayarı için, Default: #1c87f6 */
            }  

            /* Popup css değişiklikleri */    
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window {           
                background: #edeced !important; }           /* Popup window background renk ayarı için. Default: #edeced */
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-header {   
                background: #1c87f6 !important;             /* Popup header background renk ayarı için. Default: #1c87f6 */
                color: #ffffff !important;}                 /* Popup header font renk ayarı için. Default: #ffffff */
            #cookiespool-popup .cookiespool-window .cookiespool-title {
                color: #ffffff !important;      /* Popup Başlık font renk ayarı için. Default: #ffffff */
            }
            #cookiespool-popup .cookiespool-window .cookiespool-subtitle {
                color: #ffffff !important;      /* Popup Alt Başlık font renk ayarı için. Default: #ffffff */
            }
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-header #policy {
                color: #ffffff !important;}     /* Popup'taki Çerez Politikası linkinin font renk ayarı için. Default: #ffffff */
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions button {
                background: #1c87f6 !important;             /* Popup buton background renk ayarı için. Default: #1c87f6 */
                color: #ffffff !important;}      /* Popup buton font renk ayarı için. Default: #ffffff */
            #cookiespool-modal-container #cookiespool-popup .cookiespool-window .cookiespool-actions #cancel {
                background: #1c87f6 !important;             /* Popup Cancel buton background renk ayarı için. Default: #1c87f6 */
                color: #ffffff !important;}      /* Popup Cancel buton font renk ayarı için. Default: #ffffff */

            /* Responsive ekran için @media ayarları, aşağıdaki bloğu lütfen değiştirmeyin */
            @media (max-width: 1024px) {
            #cookiespool-banner {
                font-size: 0.9em !important;
                width: 90% !important; }
            #cookiespool-banner .cookiespool-cookies {
                display: flex !important;}
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
                display: flex !important;
                flex-direction: column !important; }
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
                margin: 3px 25px !important;
                font-size: 0.8em !important; } }

            @media (max-width: 500px) {
            #cookiespool-banner {
                font-size: 0.8em !important;
                width: 90% !important; }
            #cookiespool-banner .cookiespool-cookies {
                display: block !important;
                padding-bottom: 0 !important;}
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons {
                flex-direction: row !important;
                margin: 10px !important; }
            #cookiespool-banner .cookiespool-cookies .cookiespool-buttons button {
                margin: 3px 3px !important;
                font-size: 0.8em !important; } }
        
